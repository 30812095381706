import { Component, OnInit } from '@angular/core';
import {YoutubeParam} from '../../../../config/neertamba.model';
import {NeertambaPortailServices} from '../../../../config/neertamba-portail.services';
import {YOUTUBE_LIST_URL} from '../../../../config/neetambaEndpoint';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-home-video',
  templateUrl: './home-video.component.html',
  styleUrls: ['./home-video.component.scss']
})
export class HomeVideoComponent implements OnInit {
  youtubeParam: YoutubeParam;
  // just for test
  testYoutubeParam: YoutubeParam = {
    key: 'AIzaSyBSA5SE7yMhFmRp9pR-8k4RbAxVQ5pMVu0',
    playListId: 'PL8tI872RzupGQpjkOspdbU3pPbhNft0Jp',
    channelId: 'UCq215FHpgCqPTNjwJLhtgIQ'
  };
  safeUrl: any;
  constructor(private neertambaPortailService: NeertambaPortailServices,
              private _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.neertambaPortailService.fetchYoutubeParam().subscribe(res => {
      if (res.body) {
        this.youtubeParam = res.body;
      } else {
        this.youtubeParam = this.testYoutubeParam;
      }
      const url = YOUTUBE_LIST_URL.concat(this.youtubeParam.playListId);
      this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(url);
    })
  }

}
