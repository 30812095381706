import {Component, Input, OnInit} from '@angular/core';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {AppSettings, Settings} from '../../../../../app.settings';
import {AppService} from '../../../../../app.service';
import {MatDialog} from '@angular/material/dialog';
import {Actualite} from '../../../../../config/neertamba.model';

@Component({
  selector: 'app-carrousel-actualite',
  templateUrl: './carrousel-actualite.component.html',
  styleUrls: ['./carrousel-actualite.component.scss']
})
export class CarrouselActualiteComponent implements OnInit {
  @Input('actualite') actualites: Array<Actualite> = [];
  public config: SwiperConfigInterface = {};
  public settings: Settings;
  public responsiveOptions: any;
  constructor(public appSettings:AppSettings,
              public dialog: MatDialog,
  ) {
    this.settings = this.appSettings.settings;

    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '960px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '768px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  ngOnInit() {

  }

}
