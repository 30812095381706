import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NeertambaPortailServices} from '../../../../config/neertamba-portail.services';
import {StateStorageService} from '../../../../config/auth/state-storage.service';
import {Subject, takeUntil} from 'rxjs';

@Component({
  selector: 'app-download-state',
  templateUrl: './download-state.component.html',
  styleUrls: ['./download-state.component.scss']
})
export class DownloadStateComponent implements OnInit, OnDestroy {
  formGroup: UntypedFormGroup;
  cols: any[];
  errorMessage: any;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(public dialogRef: MatDialogRef<DownloadStateComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public fb: UntypedFormBuilder,
              private portailServices: NeertambaPortailServices,
              private stateStorageService: StateStorageService) {
    this.formGroup = this.fb.group({
      id: null,
      email: [null, Validators.email],
      secteurActivite: [null, Validators.required],
      typeUsage: [null, Validators.required],
      nomComplet: [null],
    });
  }

  ngOnInit(): void {}

  onSubmit() {
    const object = this.formGroup.value;
    this.portailServices.createDownload(object).pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.stateStorageService.setDownloadData(res.body);
        this.dialogRef.close(true);
      }, pro => {
        this.stateStorageService.setDownloadData(null);
        this.errorMessage = pro.error.message;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
