<h1 mat-dialog-title>{{data?.title}}</h1>

<div mat-dialog-content>
    <p>{{data?.message}}</p>
</div>

<div mat-dialog-actions>
    <div fxLayout="row" fxLayoutAlign="end center" class="w-100">
        <button mat-raised-button color="warn" (click)="onDismiss()"><mat-icon>close</mat-icon> Non</button>
        <button mat-raised-button color="primary" (click)="onConfirm()"><mat-icon>check</mat-icon> Oui</button>
    </div>
</div>
