export const SERVICE_PREFIX = `/api`;
export const YOUTUBE_URL = 'https://www.youtube.com/embed/';
export const YOUTUBE_LIST_URL = 'https://www.youtube.com/embed/videoseries?list=';
export const API_YOUTUBE = 'https://www.googleapis.com/youtube/v3/playlistItems?';
export const API_YOUTUBE_CHANEL = 'https://www.googleapis.com/youtube/v3/search?';
export const YOUTUBE_MAX_RESULT = 12;

export class NeetambaEndpoint {
  static readonly USERS = `${SERVICE_PREFIX}/users`;
  static readonly TEST_URL = `${SERVICE_PREFIX}/compte`;
  static readonly REGION_URL = `${SERVICE_PREFIX}/admin/region`;
  static readonly PROVINCE_URL = `${SERVICE_PREFIX}/admin/province`;
  static readonly COMMUNE_URL = `${SERVICE_PREFIX}/admin/commune`;
  static readonly VILLAGE_URL = `${SERVICE_PREFIX}/admin/village`;
  static readonly EXERCICE_URL = `${SERVICE_PREFIX}/admin/exercice`;
  static readonly TYPE_AMENAGEMENT_URL = `${SERVICE_PREFIX}/admin/type-amenagement`;
  static readonly TYPE_DONNEE_URL = `${SERVICE_PREFIX}/admin/type-donnee`;
  static readonly TYPE_PORTEUR_URL = `${SERVICE_PREFIX}/admin/type-porteur`;
  static readonly TYPE_ENERGIE_URL = `${SERVICE_PREFIX}/admin/type-energie`;
  static readonly TYPE_GESTION_URL = `${SERVICE_PREFIX}/admin/type-gestion`;
  static readonly TYPE_PROJET_URL = `${SERVICE_PREFIX}/admin/type-projet`;
  static readonly FACILITATEUR_URL = `${SERVICE_PREFIX}/admin/facilitateur`;
  static readonly MALLON_URL = `${SERVICE_PREFIX}/admin/mallon`;
  static readonly ENTREPRISE_URL = `${SERVICE_PREFIX}/admin/entreprise`;
  static readonly STRUCTURE_URL = `${SERVICE_PREFIX}/admin/structure`;
  static readonly DOCUMENT_URL = `${SERVICE_PREFIX}/admin/document`;
  static readonly DOCUMENT_FILE_URL = `${SERVICE_PREFIX}/admin/document-file`;
  static readonly FILIERE_URL = `${SERVICE_PREFIX}/admin/filiere`;
  static readonly FORMAT_DOCUMENT_URL = `${SERVICE_PREFIX}/admin/format-document`;
  static readonly OUTIL_COLLECT_URL = `${SERVICE_PREFIX}/admin/outil-collect`;
  static readonly SECTEUR_ACTIVITE_URL = `${SERVICE_PREFIX}/admin/secteur-activite`;
  static readonly MICRO_PROJET_URL = `${SERVICE_PREFIX}/admin/micro-projet`;
  static readonly CATEGORIE_INDICATEUR_URL = `${SERVICE_PREFIX}/admin/categorie-indicateur`;
  static readonly INDICATEUR_URL = `${SERVICE_PREFIX}/admin/indicateur`;
  static readonly AMANAGEMENT_URL = `${SERVICE_PREFIX}/admin/amenagement`;
  static readonly PDE_URL = `${SERVICE_PREFIX}/admin/pde`;
  static readonly PRODUIT_URL = `${SERVICE_PREFIX}/admin/produit`;
  static readonly INSTITUTION_FINANCIERE_URL = `${SERVICE_PREFIX}/admin/institution-financiere`;
  static readonly LIGNE_INDICATEUR_URL = `${SERVICE_PREFIX}/admin/ligne-indicateur`;
  static readonly ACTUALITE_URL = `${SERVICE_PREFIX}/admin/actualite`;
  static readonly PARTENAIRE_URL = `${SERVICE_PREFIX}/admin/partenaire`;
  static readonly ORGANIGRAMME_URL = `${SERVICE_PREFIX}/admin/organigramme`;
  static readonly CONTEXT_SITE_URL = `${SERVICE_PREFIX}/admin/context-site`;
  static readonly CONTACT_MESSAGE_URL = `${SERVICE_PREFIX}/admin/contact-message`;
  static readonly ADMIN_MAIL_URL = `${SERVICE_PREFIX}/admin/adress-mail`;
  static readonly PHOTO_URL = `${SERVICE_PREFIX}/admin/photo-theque`;
  static readonly YOUTUBE_PARAM_URL = `${SERVICE_PREFIX}/admin/youtube-param`;
  static readonly AUTHORITY_URL = `${SERVICE_PREFIX}/users/authority`;
  static readonly PROFIL_URL = `${SERVICE_PREFIX}/profils`;
  static readonly IMPORT_DATA_URL = `${SERVICE_PREFIX}/admin/import-data`;
  static readonly GESTION_SAVOIR_URL = `${SERVICE_PREFIX}/admin/gest-savoir`;
  static readonly STAT_DOWNLOAD_URL = `${SERVICE_PREFIX}/admin/stat-download`;
  static readonly STAT_DASBOARG_URL = `${SERVICE_PREFIX}/admin/stat-dasbord`;
  static readonly VISITE_URL = `${SERVICE_PREFIX}/admin/visite`;
  static readonly ALPHABETISATION_URL = `${SERVICE_PREFIX}/admin/alphabetisation`;

}

export class NeetambaPortailEndpoint {
  static readonly REGION_URL = `${SERVICE_PREFIX}/portail/region`;
  static readonly PROVINCE_URL = `${SERVICE_PREFIX}/portail/province`;
  static readonly COMMUNE_URL = `${SERVICE_PREFIX}/portail/commune`;
  static readonly VILLAGE_URL = `${SERVICE_PREFIX}/portail/village`;
  static readonly EXERCICE_URL = `${SERVICE_PREFIX}/portail/exercice`;
  static readonly TYPE_AMENAGEMENT_URL = `${SERVICE_PREFIX}/portail/type-amenagement`;
  static readonly TYPE_DONNEE_URL = `${SERVICE_PREFIX}/portail/type-donnee`;
  static readonly TYPE_PORTEUR_URL = `${SERVICE_PREFIX}/portail/type-porteur`;
  static readonly TYPE_ENERGIE_URL = `${SERVICE_PREFIX}/portail/type-energie`;
  static readonly TYPE_GESTION_URL = `${SERVICE_PREFIX}/portail/type-gestion`;
  static readonly TYPE_PROJET_URL = `${SERVICE_PREFIX}/portail/type-projet`;
  static readonly FACILITATEUR_URL = `${SERVICE_PREFIX}/portail/facilitateur`;
  static readonly MALLON_URL = `${SERVICE_PREFIX}/portail/mallon`;
  static readonly ENTREPRISE_URL = `${SERVICE_PREFIX}/portail/entreprise`;
  static readonly STRUCTURE_URL = `${SERVICE_PREFIX}/portail/structure`;
  static readonly DOCUMENT_URL = `${SERVICE_PREFIX}/portail/document`;
  static readonly DOCUMENT_FILE_URL = `${SERVICE_PREFIX}/portail/document-file`;
  static readonly FILIERE_URL = `${SERVICE_PREFIX}/portail/filiere`;
  static readonly FORMAT_DOCUMENT_URL = `${SERVICE_PREFIX}/portail/format-document`;
  static readonly OUTIL_COLLECT_URL = `${SERVICE_PREFIX}/portail/outil-collect`;
  static readonly SECTEUR_ACTIVITE_URL = `${SERVICE_PREFIX}/portail/secteur-activite`;
  static readonly MICRO_PROJET_URL = `${SERVICE_PREFIX}/portail/micro-projet`;
  static readonly CATEGORIE_INDICATEUR_URL = `${SERVICE_PREFIX}/portail/categorie-indicateur`;
  static readonly INDICATEUR_URL = `${SERVICE_PREFIX}/portail/indicateur`;
  static readonly AMANAGEMENT_URL = `${SERVICE_PREFIX}/portail/amenagement`;
  static readonly LIGNE_INDICATEUR_URL = `${SERVICE_PREFIX}/portail/ligne-indicateur`;
  static readonly ACTUALITE_URL = `${SERVICE_PREFIX}/portail/actualite`;
  static readonly PDE_URL = `${SERVICE_PREFIX}/portail/pde`;
  static readonly PARTENAIRE_URL = `${SERVICE_PREFIX}/portail/partenaire`;
  static readonly ORGANIGRAMME_URL = `${SERVICE_PREFIX}/portail/organigramme`;
  static readonly CONTEXT_SITE_URL = `${SERVICE_PREFIX}/portail/context-site`;
  static readonly COMMENTAIRE_URL = `${SERVICE_PREFIX}/portail/commentaire`;
  static readonly MESSAGE_URL = `${SERVICE_PREFIX}/portail/contact-message`;
  static readonly STAT_AMENAGEMENT_URL = `${SERVICE_PREFIX}/portail/satistiques-amenagement`;
  static readonly STAT_MICRO_PROJET_URL = `${SERVICE_PREFIX}/portail/satistiques-micro-projet`;
  static readonly YOUTUBE_PARAM_URL = `${SERVICE_PREFIX}/portail/youtube-param`;
  static readonly PHOTO_URL = `${SERVICE_PREFIX}/portail/photo-theque`;
  static readonly PHOTO_DETAIL_URL = `${SERVICE_PREFIX}/portail/photo-theque-by-photo`;
  static readonly MAPS_STATS_URL = `${SERVICE_PREFIX}/portail/map-stats`;
  static readonly TOKEN_VERIFICATION_URL = `${SERVICE_PREFIX}/portail/verif-token-validite`;
  static readonly HOME_INDICATEUR_URL = `${SERVICE_PREFIX}/portail/indicateur-list`;
  static readonly SEND_EMAIL_URL = `${SERVICE_PREFIX}/reset-password/init`;
  static readonly RESET_PASSWORD_URL = `${SERVICE_PREFIX}/reset-password/finish`;
  static readonly ACTIVATE_COMPTE_URL = `${SERVICE_PREFIX}/activate-compte`;
  static readonly GESTION_SAVOIR_URL = `${SERVICE_PREFIX}/portail/gest-savoir`;
  static readonly DONNEE_URL = `${SERVICE_PREFIX}/portail/donne-init`;
  static readonly STAT_DOWNLOAD_URL = `${SERVICE_PREFIX}/portail/stat-download`;
  static readonly EVENEMENT = `${SERVICE_PREFIX}/portail/evenement-marquant`;
  static readonly ALPHABETISATION_URL = `${SERVICE_PREFIX}/portail/alphabetisation`;
  static readonly VISITE_URL = `${SERVICE_PREFIX}/portail/visite`;
  static readonly STATE_RESSOURCE_URL = `${SERVICE_PREFIX}/portail/nombre-ressource`;
}
