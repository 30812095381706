<div *ngIf="actualites" class="swiper-container" [swiper]="config">
  <p-carousel [value]="actualites" styleClass="custom-carousel" id="actualite-caroussel"
              [numVisible]="1" [numScroll]="1" [circular]="true"
              [autoplayInterval]="10000" [showIndicators]="false"
              [responsiveOptions]="responsiveOptions">
    <ng-template let-product pTemplate="item">
      <div class="product-item">
        <mat-chip-list *ngIf="product.nouveau">
          <mat-chip color="warn" selected="true">Nouveau</mat-chip>
        </mat-chip-list>
        <div class="product-item-content">
          <!--<div class="actu-image">
            <a [routerLink]="['/portail/actualite', product.id]" class="image-link">
              <img [src]="'data:image/jpg;base64,'+ product.image" [alt]="product.titre" class="product-image"/>
            </a>
          </div>-->
          <div>
            <div class="actu-text">
              <a [routerLink]="['/portail/actualite', product.id]" class="title">
                <h4 class="mb-1">{{product.titre | truncate: [100, '...']}}</h4>
                <!--<p>
                  {{product.sousTitre | truncate: [100, '...']}}
                </p>-->
              </a>
            </div>
            <!--<div class="car-buttons text-center">
              <p class="new-price">{{product.datePubier | date: 'dd/MM/yyyy à HH:mm'}}</p>
            </div>-->
            <div>
              <button class="mt-1" [routerLink]="['/portail/actualite', product.id]"  mat-raised-button color="primary">Lire plus</button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-carousel>
</div>
