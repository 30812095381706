import {Injectable} from '@angular/core';
import {StatDataDownload, Visiteur} from '../neertamba.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class StateStorageService {
  constructor(private http: HttpClient) {
  }

  storeUrl(url: string) {
    if (url) {
      localStorage.setItem('previous_url', url);
    } else {
      localStorage.removeItem('previous_url');
    }
  }

  getUrl() {
    return localStorage.getItem('previous_url');
  }

  setDownloadData(data: any) {
    const jsonData = JSON.stringify(data)
    localStorage.setItem('downloadData', jsonData)
  }

  getDownloadData(): StatDataDownload {
    const data = localStorage.getItem('downloadData');
    if (data) {
      return JSON.parse(data);
    }
    return null;
  }

  reomoveDownloadData() {
    return localStorage.removeItem('downloadData');
  }


  setVisite(data: any) {
    const jsonData = JSON.stringify(data)
    localStorage.setItem('visite', jsonData)
  }

  getVisite(): Visiteur {
    const data = localStorage.getItem('visite');
    if (data) {
      return JSON.parse(data);
    }
    return null;
  }

  reomoveVisite() {
    return localStorage.removeItem('visite');
  }
}
