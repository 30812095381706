<div fxLayout="row wrap">
    <div fxFlex="100">
        <div fxLayout="row wrap" fxLayoutAlign="space-around">
            <div fxFlex="80" fxFlex.md="100" fxFlex.sm="100" class="p-1">
                <div class="text-color" fxLayoutAlign="center">
                    <h1>Vidéothèque </h1>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-xs="40">
                <div fxLayout="column" fxLayoutAlign="center" class="w-100 video-container">
                    <iframe [src]='safeUrl' frameborder="0" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>