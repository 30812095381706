<mat-card>
  <mat-card-title><h1 mat-card-title>Informations liées aux téléchargement</h1>
    <mat-divider></mat-divider>
  </mat-card-title>
  <mat-card-content>
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" autocomplete="off" role="form">
      <div mat-dialog-content>
        <div fxLayout="row wrap">
          <div fxFlex="100">
            <mat-form-field appearance="legacy" class="w-100">
              <mat-label>Email</mat-label>
              <input matInput formControlName="email" name="email"/>
              <mat-error *ngIf="formGroup.controls.email.errors?.required">
                Champs obligatoire
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="100">
            <mat-form-field appearance="legacy" class="w-100">
              <mat-label>Votre Secteur d'activité</mat-label>
              <input matInput formControlName="secteurActivite" name="secteurActivite"/>
              <mat-error *ngIf="formGroup.controls.secteurActivite.errors?.required">
                Champs obligatoire
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="100">
            <mat-form-field appearance="legacy" class="w-100">
              <mat-label>A quel fin souhaitez vous utiliser les données</mat-label>
              <input matInput formControlName="typeUsage" name="typeUsage"/>
              <mat-error *ngIf="formGroup.controls.typeUsage.errors?.required">
                Champs obligatoire
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div mat-dialog-actions>
        <div fxLayout="row" fxLayoutAlign="end center" class="w-100">
          <button mat-raised-button color="warn" type="button" mat-dialog-close>
            <mat-icon>close</mat-icon>
            Fermer
          </button>
          <button mat-raised-button color="primary" type="submit" [disabled]="formGroup.invalid">
            <mat-icon>save</mat-icon>
            Enregistrer
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
