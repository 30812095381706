export enum ETypeCommune {
  RURALE = 'RURALE', URBAINE = 'URBAINE'
}

export enum EcodeFormatDoc {
  PDF = 'pdf',
  DOCX = 'docx',
  EXCEL = 'excel',
  CSV = 'csv',
  POWERPOINT = 'pptx',
//  BZ = 'bz',
  ZIP = 'zip',
  JPEG = 'jpeg',
  PNG = 'png',
}

export enum AcceptFormatDoc {
  PDF = 'application/pdf',
 // WORDS = 'application/*',
  WORDS = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword',
  EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel',
  POWERPOINTE = 'application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-powerpoint',
  CSV = 'text/csv',
 // BZ = 'application/x-bzip,application/x-bzip2',
  ZIP = 'application/zip',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
}


export function tableDataFilter(inputValue, items: any[]): any[] {
  if (!items) return [];
  if (!inputValue) return items;
  return items.filter(item => {
    return Object.keys(item).some(key => {
      return String(item[key]).toLowerCase().includes(inputValue.trim().toLowerCase());
    });
  });
}

export const legendColorGradient: Array<any> = [
  '#004141',
  '#006969',
  '#009191',
  '#00b9b9',
  '#00e1e1',
  '#00e131',
  '#00B1b9',
  '#00B1C9',
  '#283593',
  '#039BE5',
  '#FF5252',
  '#fffc52',
  '#d0d0b9',
  '#0fecc0',
  '#aebab7',
  '#c69484',
  '#5e5552',
  '#ce80bb',
  '#580444',
  '#e7b5db',
  '#80787e',
  '#170f15'
];

export enum JeuxDonne {
  PEDE = 'PEDE',
  AMENAGEMENT = 'AMENAGEMENT',
  MICROPROJET = 'MICROPROJET',
  ALPHABETISATION = 'ALPHABETISATION',
  AUTRE = 'AUTRE',
  SUIVI_EVALUATION = 'SUIVI_EVALUATION',
}

export const JeuxDonnesList = [
  {value: JeuxDonne.PEDE, label: 'PDE'},
  {value: JeuxDonne.AMENAGEMENT, label: 'AMENAGEMENT'},
  {value: JeuxDonne.MICROPROJET, label: 'MICRO PROJT'},
];

export enum TypeDocument {
  FICHE_CAPITALISATION = 'FICHE_CAPITALISATION',
  RAPPORT_EFFET = 'RAPPORT_EFFET',
  RAPPORT_ACTIVITE = 'RAPPORT_ACTIVITE',
  RAPPORT_ANNUEL = 'RAPPORT_ANNUEL',
  ETUDE = 'ETUDE',
  AIDE_MEMOIRE = 'AIDE_MEMOIRE',
  MISION_SUPERVISION = 'MISION_SUPERVISION',
  MISION_APPUI = 'MISION_APPUI',
  AUTRE = 'AUTRE',
}

export const DocumentSourceList = [
  {value: JeuxDonne.PEDE, label: 'PDE'},
  {value: JeuxDonne.AMENAGEMENT, label: 'AMENAGEMENT'},
  {value: JeuxDonne.MICROPROJET, label: 'MICRO PROJET'},
  {value: JeuxDonne.ALPHABETISATION, label: 'ALPHABETISATION'},
  {value: JeuxDonne.SUIVI_EVALUATION, label: 'SUIVI EVALUATION'},
  {value: JeuxDonne.AUTRE, label: 'AUTRE SOURCE'},
];
export const TYPEDOCList = [
  {value: TypeDocument.RAPPORT_ANNUEL, label: 'RAPPORT ANNUEL'},
  {value: TypeDocument.RAPPORT_EFFET, label: 'RAPPORT EFFET'},
  {value: TypeDocument.RAPPORT_ACTIVITE, label: 'RAPPORT D\'ACTIVITE'},
  {value: TypeDocument.FICHE_CAPITALISATION, label: 'FICHE DE CAPITALISATION'},
  {value: TypeDocument.ETUDE, label: 'ETUDE'},
  {value: TypeDocument.AIDE_MEMOIRE, label: 'AIDE MEMOIRE'},
  {value: TypeDocument.MISION_SUPERVISION, label: 'MISSION DE SUPERVISION'},
  {value: TypeDocument.MISION_APPUI, label: 'MISSION D\'APPUI'},
  {value: TypeDocument.AUTRE, label: 'AUTRE DOCUMENT'}
];
export const imageBase64 = 'data:image/jpeg;base64,';

export enum TypePresentation {
  Context = 'Context', Projet = 'Projet'
}

export enum LOCALITE {
  REGION = 'REGION',
  PROVINCE = 'PROVINCE',
  COMMUNE = 'COMMUNE'
}

export const higchartsDowlowndOption = {
  lang: {
    contextButtonTitle: 'Menu de téléchargement ',
    printChart: 'Imprimer',
    viewFullscreen: 'Plein écran',
    downloadPNG: 'Télécharger PNG',
    downloadJPEG: 'Télécharger JPEG',
    downloadPDF: 'Télécharger PDF',
    downloadSVG: 'Télécharger SVG'
  }
}
