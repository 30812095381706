<mat-card>
  <p-carousel [value]="partenaires"
              [numVisible]="5" [numScroll]="5" [circular]="true"
              [autoplayInterval]="3500" [showIndicators]="true">
    <ng-template let-partenaire pTemplate="item">
      <div class="text-center">
        <a class="" [href]="partenaire.siteUrl" target="_blank">
          <img [src]="partenaire.file" [alt]="''" class="image"/>
        </a>
      </div>
    </ng-template>
  </p-carousel>
</mat-card>

