import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  Actualite,
  AlphabetisationStats,
  Amenagement,
  AmenagementPointDto,
  CategorieIndicateur,
  Commentaire,
  Commune,
  CommuneSort,
  ContextSite,
  DialogData,
  Document,
  DocumentFile,
  Donnee,
  Exercice,
  FormatDocument,
  GestSavoir,
  Indicateur,
  LigneIndicateur,
  localiteList,
  MapSelected,
  MicroProjet,
  Partenaire,
  Pde,
  PhotoTheque,
  Province,
  ProvinceSort,
  Region,
  RegionSort,
  StatAmDataDto,
  StatDataDownload,
  StatMicroProjetDataDto,
  Village,
  Visiteur,
  YoutubeParam
} from './neertamba.model';
import {createRequestOption} from '../theme/utils/request-util';
import {
  API_YOUTUBE,
  API_YOUTUBE_CHANEL,
  NeetambaEndpoint,
  NeetambaPortailEndpoint,
  YOUTUBE_MAX_RESULT
} from './neetambaEndpoint';
import {environment} from '../../environments/environment';
import {TypePresentation} from './constants';

@Injectable({providedIn: 'root'})
export class NeertambaPortailServices {
    public imageUrl = environment.url + '/assets/data/';
    private API_Youtube: string = API_YOUTUBE;
    private API_Youtube_Chanel: string = API_YOUTUBE_CHANEL;

    url(path: string) {
        return this.API_Youtube + path;
    }

    url1(path: string) {
        return this.API_Youtube_Chanel + path;
    }

    constructor(protected http: HttpClient) {
    }

    public fetchRegion(options?: any): Observable<HttpResponse<Region[]>> {
        return this.http.get<Region[]>(NeetambaPortailEndpoint.REGION_URL, {params: options, observe: 'response'});
    }

    public fetchProvince(options: any): Observable<HttpResponse<Province[]>> {
        return this.http.get<Province[]>(NeetambaPortailEndpoint.PROVINCE_URL,
            {params: options, observe: 'response'});
    }

    public fetchCommune(options: any): Observable<HttpResponse<Commune[]>> {
        return this.http.get<Commune[]>(NeetambaPortailEndpoint.COMMUNE_URL,
            {params: options, observe: 'response'});
    }

    public fetchVillage(options: any): Observable<HttpResponse<Village[]>> {
        return this.http.get<Village[]>(NeetambaPortailEndpoint.VILLAGE_URL,
            {params: options, observe: 'response'});
    }

    public fetchExercice(): Observable<HttpResponse<Exercice[]>> {
        return this.http.get<Exercice[]>(NeetambaPortailEndpoint.EXERCICE_URL, {observe: 'response'});
    }

    public fetchDocument(object: any): Observable<HttpResponse<Document[]>> {
        return this.http.patch<Document[]>(NeetambaPortailEndpoint.DOCUMENT_URL, object, {observe: 'response'});
    }

    public fetchDocumentFile(documentId: number): Observable<HttpResponse<DocumentFile>> {
        return this.http.get<DocumentFile>(`${NeetambaPortailEndpoint.DOCUMENT_FILE_URL}/${documentId}`,
            {observe: 'response'});
    }

    // FormatDocument service
    public fetchFormatDocument(): Observable<HttpResponse<FormatDocument[]>> {
        return this.http.get<FormatDocument[]>(NeetambaPortailEndpoint.FORMAT_DOCUMENT_URL, {observe: 'response'});
    }

    public fetchMicroProjet(object: any): Observable<HttpResponse<MicroProjet[]>> {
        return this.http.put<MicroProjet[]>(NeetambaPortailEndpoint.MICRO_PROJET_URL, object, {observe: 'response'});
    }

    public fetchCategorieIndicateur(): Observable<HttpResponse<CategorieIndicateur[]>> {
        return this.http.get<CategorieIndicateur[]>(NeetambaPortailEndpoint.CATEGORIE_INDICATEUR_URL,
            {observe: 'response'});
    }

    /* public fetchIndicateur(isSousIndicateur?: boolean): Observable<HttpResponse<Indicateur[]>> {
      const options = createRequestOption({isSousIndicateur});
      return this.http.get<Indicateur[]>(NeetambaPortailEndpoint.INDICATEUR_URL,
        {params: options, observe: 'response'});
    } */

    public fetchIndicateurByCategorie(options: any): Observable<HttpResponse<Indicateur[]>> {
        return this.http.get<Indicateur[]>(NeetambaPortailEndpoint.INDICATEUR_URL, {
            params: options, observe: 'response'
        });
    }

    // service d'amenagement
    public fetchAmenagement(object: any): Observable<HttpResponse<Amenagement[]>> {
        return this.http.put<Amenagement[]>(NeetambaPortailEndpoint.AMANAGEMENT_URL, object, {observe: 'response'});
    }

    // service d'amenagement
    public fetchLigneIndicateurByIndicteur(indicateurId: number): Observable<HttpResponse<LigneIndicateur[]>> {
        const options = createRequestOption({indicateurId});
        return this.http.get<LigneIndicateur[]>(NeetambaPortailEndpoint.LIGNE_INDICATEUR_URL,
            {params: options, observe: 'response'});
    }

    public loadImages(): Observable<any[]> {
        return this.http.get<any[]>(this.imageUrl + 'doc.json');
    }

    // service d'actualite
    public fetchListActualite(withpic: boolean): Observable<HttpResponse<Actualite[]>> {
        const params = createRequestOption({withpic});
        return this.http.get<Actualite[]>(NeetambaPortailEndpoint.ACTUALITE_URL,
            {observe: 'response', params});
    }

    public fetchPde(object: any): Observable<HttpResponse<Pde[]>> {
        return this.http.put<Pde[]>(NeetambaPortailEndpoint.PDE_URL, object, {observe: 'response'});
    }

    public fetchContextProjet(typePresentation: TypePresentation): Observable<HttpResponse<ContextSite[]>> {
        const options = createRequestOption({typePresentation});
        return this.http.get<ContextSite[]>(NeetambaPortailEndpoint.CONTEXT_SITE_URL,
            {params: options, observe: 'response'});
    }

    public fetchPartenaire(): Observable<HttpResponse<Partenaire[]>> {
        return this.http.get<Partenaire[]>(NeetambaPortailEndpoint.PARTENAIRE_URL, {observe: 'response'});
    }

    public fetchOrganigrammeTree(): Observable<HttpResponse<any>> {
        return this.http.get<any>(NeetambaPortailEndpoint.ORGANIGRAMME_URL, {observe: 'response'});
    }

    public fetchActualiteById(id: number): Observable<HttpResponse<Actualite>> {
        return this.http.get<Actualite>(
            `${NeetambaPortailEndpoint.ACTUALITE_URL}/${id}`, {observe: 'response'});
    }

    // service de commentaire

    public createCommentaire(commentaire: Commentaire): Observable<HttpResponse<Commentaire>> {
        return this.http.post<Commentaire>(NeetambaPortailEndpoint.COMMENTAIRE_URL, commentaire,
            {observe: 'response'});
    }

    public fetchCommentaireListByActualite(idActualite: number): Observable<HttpResponse<Commentaire[]>> {
        return this.http.get<Commentaire[]>(`${NeetambaPortailEndpoint.COMMENTAIRE_URL}/${idActualite}`,
            {observe: 'response'});
    }

    // service de contactMessage
    public createContactMessage(commentaire: Commentaire): Observable<HttpResponse<Commentaire>> {
        return this.http.post<Commentaire>(NeetambaPortailEndpoint.MESSAGE_URL, commentaire,
            {observe: 'response'});
    }

    public fetchStatAmenagement(): Observable<HttpResponse<StatAmDataDto>> {
        return this.http.get<StatAmDataDto>(`${NeetambaPortailEndpoint.STAT_AMENAGEMENT_URL}`,
            {observe: 'response'});
    }

    public fetchStatMicroProjet(): Observable<HttpResponse<StatMicroProjetDataDto>> {
        return this.http.get<StatMicroProjetDataDto>(`${NeetambaPortailEndpoint.STAT_MICRO_PROJET_URL}`,
            {observe: 'response'});
    }

    public fetchPhotos(size: number): Observable<HttpResponse<PhotoTheque[]>> {
        return this.http.get<PhotoTheque[]>(`${NeetambaPortailEndpoint.PHOTO_URL}`,
            {params: createRequestOption({size}), observe: 'response'});
    }

    public fetchPhotosDetail(photoId: number): Observable<HttpResponse<DocumentFile[]>> {
        return this.http.get<DocumentFile[]>(`${NeetambaPortailEndpoint.PHOTO_DETAIL_URL}`,
            {params: createRequestOption({photoId}), observe: 'response'});
    }

    //************** Youtube playList list services
    getplaylist(playlistId, key): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().append('Content-Type', 'application/json');
        return this.http.get(this.url('part=snippet&playlistId=' + playlistId + '&key=' + key + '&maxResults=' + YOUTUBE_MAX_RESULT),
            {headers, observe: 'response'});
    }

    playlistList_page(playlistId, pageToken, key): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().append('Content-Type', 'application/json');
        return this.http.get(this.url('part=snippet&pageToken=' + pageToken + '&channelId='
                + playlistId + '&key=' + key + '&maxResults=' + YOUTUBE_MAX_RESULT),
            {headers, observe: 'response'});
    }

    // "https://www.googleapis.com/youtube/v3/search?part=snippet&pageToken="+this.nextPageToken+"&maxResults="+this.result+"&channelId="+this.chanId+"&type=video&videoType=any&key="+this.api+"";

    nextList_page(chanId, pageToken, key): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().append('Content-Type', 'application/json');
        return this.http.get(this.url1('part=snippet&pageToken=' + pageToken + '&maxResults=' + YOUTUBE_MAX_RESULT +
                '&channelId=' + chanId + '&type=video&key=' + key),
            {headers, observe: 'response'});
    }

    getVideoListByChanel(channelId, key): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().append('Content-Type', 'application/json');
        return this.http.get(this.url1('part=snippet&channelId=' + channelId + '&key=' + key
                + '&type=video&maxResults=' + YOUTUBE_MAX_RESULT),
            {headers, observe: 'response'});
    }

    fetchYoutubeParam(): Observable<HttpResponse<YoutubeParam>> {
        return this.http.get<YoutubeParam>(`${NeetambaPortailEndpoint.YOUTUBE_PARAM_URL}`,
            {observe: 'response'});
    }

    public fetchMapGlobalData(query: any): Observable<DialogData> {
        return this.http.get<DialogData>(`${NeetambaPortailEndpoint.MAPS_STATS_URL}/global`,
            {params: createRequestOption(query)});
    }

    public fetchMapMicroprojetData(query: any): Observable<Array<MapSelected>> {
        return this.http.get<Array<MapSelected>>(`${NeetambaPortailEndpoint.MAPS_STATS_URL}/microprojet`,
            {params: createRequestOption(query)});
    }

    public fetchPointAmenagement(query: any): Observable<Array<AmenagementPointDto>> {
        return this.http.get<Array<AmenagementPointDto>>(`${NeetambaPortailEndpoint.MAPS_STATS_URL}/amenagement-point`,
            {params: createRequestOption(query)});
    }

    public fetchPointAmenagementData(query: any): Observable<Amenagement> {
        return this.http.get<Amenagement>(`${NeetambaPortailEndpoint.MAPS_STATS_URL}/amenagement-point-data`,
            {params: createRequestOption(query)});
    }

    public homePageIndicateurList(): Observable<any> {
        return this.http.get<any>(`${NeetambaPortailEndpoint.HOME_INDICATEUR_URL}`,
            {observe: 'response'});
    }

    public fetchGestSavoir(): Observable<HttpResponse<GestSavoir[]>> {
        return this.http.get<GestSavoir[]>(NeetambaPortailEndpoint.GESTION_SAVOIR_URL, {observe: 'response'});
    }

    public fetchDonnee(): Observable<HttpResponse<Donnee[]>> {
        return this.http.get<Donnee[]>(NeetambaPortailEndpoint.DONNEE_URL, {observe: 'response'});
    }

    public createDownload(object: StatDataDownload): Observable<HttpResponse<StatDataDownload[]>> {
        return this.http.post<StatDataDownload[]>(NeetambaPortailEndpoint.STAT_DOWNLOAD_URL, object,
            {observe: 'response'});
    }

    public fetchEvenementMarquant(pageNo: number, pageSize: number): Observable<HttpResponse<any>> {
        const options = createRequestOption({pageNo, pageSize});
        return this.http.get(NeetambaPortailEndpoint.EVENEMENT,
            {params: options, observe: 'response'});
    }

    fetchCommuneByProvince(ids: string[]): Observable<Array<localiteList>> {
        return this.http.get<Array<CommuneSort>>(`${NeetambaPortailEndpoint.COMMUNE_URL}/sort`, {params: {ids}});
    }

    fetchProvinceByRegion(ids: string[]): Observable<Array<localiteList>> {
        return this.http.get<Array<ProvinceSort>>(`${NeetambaPortailEndpoint.PROVINCE_URL}/sort`, {params: {ids}});
    }

    fetchRegions(ids: string[]): Observable<Array<localiteList>> {
        return this.http.get<Array<RegionSort>>(`${NeetambaPortailEndpoint.REGION_URL}/sort`, {params: {ids}});
    }

    public fetchMapAlphabetisionData(query: any): Observable<Array<MapSelected>> {
        return this.http.get<Array<MapSelected>>(`${NeetambaPortailEndpoint.MAPS_STATS_URL}/alphabetisation`,
            {params: createRequestOption(query)});
    }

    public fetchAlphabetisionByRegion(query: any): Observable<Array<AlphabetisationStats>> {
        return this.http.get<Array<AlphabetisationStats>>(`${NeetambaPortailEndpoint.ALPHABETISATION_URL}/details`,
            {params: createRequestOption(query)});
    }

  public createVisite(entity: Visiteur): Observable<HttpResponse<Visiteur>> {
    return this.http.post<Visiteur>(NeetambaPortailEndpoint.VISITE_URL, entity, {observe: 'response'});
  }

  public fetchAllphabetisation(object: any): Observable<HttpResponse<AlphabetisationStats[]>> {
    return this.http.put<AlphabetisationStats[]>(NeetambaPortailEndpoint.ALPHABETISATION_URL, object, {observe: 'response'});
  }
  public counteRessource(): Observable<HttpResponse<any>> {
    return this.http.get(NeetambaPortailEndpoint.STATE_RESSOURCE_URL, {observe: 'response'});
  }
}
