import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SwiperModule} from 'ngx-swiper-wrapper';
import {TranslateModule} from '@ngx-translate/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';

import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';
import {PipesModule} from '../theme/pipes/pipes.module';
import {RatingComponent} from './rating/rating.component';
import {ControlsComponent} from './controls/controls.component';
import {MainCarouselComponent} from './main-carousel/main-carousel.component';
import {BrandsCarouselComponent} from './brands-carousel/brands-carousel.component';
import {BannersComponent} from './banners/banners.component';
import {CategoryListComponent} from './category-list/category-list.component';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {LangComponent} from './lang/lang.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {NgxPaginationModule} from 'ngx-pagination';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {HighchartsChartModule} from 'highcharts-angular';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true
};
import {NgxEditorModule} from 'ngx-editor';
import {CarrouselActualiteComponent} from '../neertamba/pages/page-portail-neertamba/components/carrousel-actualite/carrousel-actualite.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {BadgeModule} from 'primeng/badge';
import {CarouselModule} from 'primeng/carousel';
import {IConfig, MaskApplierService, NgxMaskModule} from 'ngx-mask';
import {ToastModule} from 'primeng/toast';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {HomeGraphComponent} from '../neertamba/pages/components/home-graph/home-graph.component';
import {HomeVideoComponent} from '../neertamba/pages/components/home-video/home-video.component';
import {DownloadStateComponent} from '../neertamba/pages/components/download-state/download-state.component';
import {NeerTambaModule} from '../neertamba/admin/page-neertamba/neer-tamba.module';

const maskConfig: Partial<IConfig> = {
  validation: false
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SwiperModule,
    TranslateModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PerfectScrollbarModule,
    PipesModule,
    MatFormFieldModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    FormsModule,
    NgxChartsModule,
    HighchartsChartModule,
    NgxEditorModule,
    ScrollingModule,
    BadgeModule,
    CarouselModule,
    NgxMaskModule.forRoot(maskConfig),
    ToastModule,
    ProgressSpinnerModule,
  ],
  exports: [
    RouterModule,
    SwiperModule,
    TranslateModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PerfectScrollbarModule,
    PipesModule,
    RatingComponent,
    ControlsComponent,
    MainCarouselComponent,
    BrandsCarouselComponent,
    BannersComponent,
    CategoryListComponent,
    ConfirmDialogComponent,
    LangComponent,
    MatFormFieldModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    FormsModule,
    NgxChartsModule,
    HighchartsChartModule,
    NgxEditorModule,
    CarrouselActualiteComponent,
    ScrollingModule,
    BadgeModule,
    CarouselModule,
    NgxMaskModule,
    ToastModule,
    ProgressSpinnerModule,
    HomeGraphComponent,
    HomeVideoComponent,
    DownloadStateComponent
  ],
  declarations: [
    RatingComponent,
    ControlsComponent,
    MainCarouselComponent,
    BrandsCarouselComponent,
    BannersComponent,
    CategoryListComponent,
    ConfirmDialogComponent,
    LangComponent,
    CarrouselActualiteComponent,
    HomeGraphComponent,
    HomeVideoComponent,
    DownloadStateComponent
  ],
  providers: [
    MaskApplierService, MessageService, ConfirmationService,
    {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
  ]
})
export class SharedModule {
}
