import {Component, OnInit, ViewChild} from '@angular/core';
import {JeuxDonne} from '../../../../config/constants';
import * as Highcharts from 'highcharts';
import {BuildChartAnalyse, StatAmDataDto, StatMicroProjetDataDto} from '../../../../config/neertamba.model';
import {NeertambaPortailServices} from '../../../../config/neertamba-portail.services';

@Component({
  selector: 'app-home-graph',
  templateUrl: './home-graph.component.html',
  styleUrls: ['./home-graph.component.scss']
})
export class HomeGraphComponent implements OnInit {
  @ViewChild('sidenav', {static: true}) sidenav: any;
  public sidenavOpen: boolean = true;
  public viewCol: number = 25;
  public counts = [10, 50, 100];
  public count: any;
  jeuxDonnees: any[];
  filterObject: any = {};
  typeJeuDonne = JeuxDonne;
  cols: any[] = [];
  listDatas: any[] = [];
  page: any;
  Highcharts = Highcharts;
  chartDiagrammesAmenagements: BuildChartAnalyse[] = [];
  chartDiagrammesMicroprojet: BuildChartAnalyse[] = [];

  constructor(private portailServices: NeertambaPortailServices) {
    this.jeuxDonnees = [
      {value: JeuxDonne.AMENAGEMENT, label: 'AMENAGEMENT'},
      {value: JeuxDonne.MICROPROJET, label: 'MICRO-PROJET'},
    ]
  }

  ngOnInit(): void {
    if (window.innerWidth < 960) {
      this.sidenavOpen = false;
    }
    if (window.innerWidth < 1280) {
      this.viewCol = 33.3;
    }
    this.filterObject = {jeuxDonnee: JeuxDonne.AMENAGEMENT};
    this.onJeuxDonneeChange()
  }

  onJeuxDonneeChange() {
    this.chartDiagrammesAmenagements = [];
    this.chartDiagrammesMicroprojet = [];
    this.loadDatas(this.filterObject);
  }

  loadDatas(object: any) {
    this.listDatas = [];
    if (object.jeuxDonnee === JeuxDonne.PEDE) {
      this.loadPde(object);
    } else if (object.jeuxDonnee === JeuxDonne.AMENAGEMENT) {
      this.loadAmenagementDatas();
    } else if (object.jeuxDonnee === JeuxDonne.MICROPROJET) {
      this.loadMicroProjetsData(object);
    }
  }

  loadPde(object: any) {
    this.portailServices.fetchPde(object).subscribe(res => {
      if (res.body) {
        this.listDatas = [...res.body];
      }
    });
  }

  loadAmenagementDatas() {
    this.portailServices.fetchStatAmenagement().subscribe(res => {
      const typeStat: StatAmDataDto = res.body;
      const datasAnne: any[] = [];
      typeStat.evolutionsParAnnee.forEach(c => {
        const data = {
          label: c.annee.toString(),
          value: c.nombre
        };
        datasAnne.push(data)
      });
      // cas 1: nombre par region
      this.chartDiagrammesAmenagements.push({
        label: 'Nombre d\'aménagements par région',
        chartOption: this.buildChartOptions('column', 'Région',
            typeStat.nombreAmParRegions.map(c => c.libelle), 'Nombre',
            typeStat.nombreAmParRegions.map(c => c.nombre), 1)
      });
      // cas 2: nombre par type aménagement
      this.chartDiagrammesAmenagements.push({
        label: 'Nombre de bénéficiaires par type d\'aménagement',
        chartOption: this.buildChartOptions('column', 'Type aménagement',
            typeStat.nombreParTypeAmenagements.map(c => c.libelle), 'Nombre',
            typeStat.nombreParTypeAmenagements.map(c => c.nombre), 1)
      });
      // cas 2: superficie par type amenagemant
      this.chartDiagrammesAmenagements.push({
        label: 'Superficie par type d\'amenagement',
        chartOption: this.buildChartOptions('column', 'Type aménagement',
            typeStat.superficeParTypeAmenagements.map(c => c.libelle), 'Superficie',
            typeStat.superficeParTypeAmenagements.map(c => c.superficie), 1)
      });
      // cas 4: evolution par année
      this.chartDiagrammesAmenagements.push({
        label: 'Evolution des aménagements en fonction des années',
        chartOption: this.buildChartOptions('line', 'Année',
            datasAnne.map(c => c.label), 'Nombre',
            datasAnne.map(c => c.value), 1)
      });
    });
  }

  loadMicroProjetsData(object: any) {
    this.portailServices.fetchStatMicroProjet().subscribe(res => {
      const typeStat: StatMicroProjetDataDto = res.body;
      const datasAnne: any[] = [];
      typeStat.evolutionAnuelle.forEach(c => {
        const data = {
          label: c.annee.toString(),
          value: c.nombre
        };
        datasAnne.push(data)
      });
      // cas 1: nombre par region
      this.chartDiagrammesMicroprojet.push({
        label: 'Nombre de microprojet par région',
        chartOption: this.buildChartOptions('column', 'Région',
            typeStat.nombreParRegions.map(c => c.libelle), 'Nombre',
            typeStat.nombreParRegions.map(c => c.nombre), 1)
      });
      // cas 2: nombre par sacteur d'activite
      this.chartDiagrammesMicroprojet.push({
        label: 'Nombre de microprojet par secteur d\'activité',
        chartOption: this.buildChartOptions('column', 'Région',
            typeStat.nombreParSecteurActivite.map(c => c.libelle), 'Nombre',
            typeStat.nombreParSecteurActivite.map(c => c.nombre), 1)
      });

      // cas 3: couts
      const subDatas = [
        {name: 'Coût total', y: typeStat.couts.coutTotal},
        {name: 'Suvention', y: typeStat.couts.totalSubvention},
        {name: 'Apport en espèce', y: typeStat.couts.totalApportEspece},
      ];
      this.chartDiagrammesMicroprojet.push({
        label: 'Cout total des mircoprojets, les subventions et apports rédimentionnées',
        chartOption: this.buildChartOptions('pie', 'Région',
            subDatas.map(c => c.name), 'Nombre',
            subDatas, 2)
      });
      this.chartDiagrammesMicroprojet.push({
        label: 'Evolution des micro-projets en fonction des années',
        chartOption: this.buildChartOptions('line', 'Année',
            datasAnne.map(c => c.label), 'Nombre',
            datasAnne.map(c => c.value), 1)
      });
    });
  }

  buildChartOptions(type: any, catName: any, categories: any[], dataName: any, datas: any[], id: number): any {
    if (id == 1) {
      return {
        chart: {
          alignTicks: false
        },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: [
                'viewFullscreen', 'separator', 'downloadPNG',
                'downloadSVG', 'downloadPDF', 'separator', 'downloadXLS'
              ]
            },
          },
          enabled: true,
        },
        navigation: {
          buttonOptions: {
            align: 'right',
            verticalAlign: 'top',
            y: 0
          }
        },
        title: {
          text: ''
        },
        legend: {
          enabled: false
        },
        accessibility: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        xAxis: {
          name: catName,
          categories: categories
        },
        yAxis: {
          title: {
            text: '',
          },
          labels: {
            formatter: function () {
              return this.value;
            }
          },
        },
        series: [{
          name: dataName,
          type: type,
          data: datas,
          colorByPoint: true,
        }],
      };
    } else {
      return {
        chart: {
          type: type,
        },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: [
                  'downloadPNG', 'downloadSVG', 'downloadPDF', 'separator', 'downloadXLS'
              ]
            },
          },
          enabled: true,
        },
        navigation: {
          buttonOptions: {
            align: 'right',
            verticalAlign: 'top',
            y: 0
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            showInLegend: true,
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>'
            }
          }
        },
        title: {
          text: ''
        },
        legend: {
          enabled: true
        },
        /*tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}</b>'
        },*/
        accessibility: {
          enabled: false,
          point: {
            valueSuffix: ''
          }
        },
        credits: {
          enabled: false
        },
        series: [{
          name: 'Montant',
          colorByPoint: true,
          data: datas,
        }],
      };
    }
  }
}
