<div fxLayout="row wrap">
    <div fxFlex="100">
        <div fxLayout="row wrap" fxLayoutAlign="space-around">
            <div fxFlex="100" fxFlex.gt-xs="90">
                <div fxLayout="column" fxLayoutAlign="left">
                    <div fxLayout="row wrap">
                        <!--<div fxFlex="80" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" class="p-1 text-color">
                            <div fxLayoutAlign="flex-start">
                                <h1>Répresentation en graphe de certaines données </h1>
                            </div>
                            <div fxLayoutAlign="flex-start" class="filter-description">
                                <p class="description-contetent">
                                    Les graphes ci-apres répresentent une séletion sommaire des données du portail.
                                    Il est possible d'afficher les graphes grâce aux filtres en fonction du type d'informations souhaitées.
                                </p>
                            </div>
                        </div>
-->
                        <div fxFlex="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" class="p-1">
                            <div class="mat-card">
                                <mat-form-field appearance="legacy" class="w-100 mr-1">
                                    <mat-label>Jeux de données</mat-label>
                                    <mat-select [(ngModel)]="filterObject.jeuxDonnee" (selectionChange)="onJeuxDonneeChange()">
                                        <mat-option *ngFor="let select of jeuxDonnees" [value]="select.value">
                                            <span>{{select.label}}</span>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>

                <div fxLayout="column" fxLayoutAlign="center">
                    <div fxLayout="row wrap">
                        <ng-container *ngIf="filterObject.jeuxDonnee===typeJeuDonne.AMENAGEMENT">
                            <div fxFlex="25" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" class="p-2" *ngFor="let data of chartDiagrammesAmenagements">
                                <div class="mat-card ng-card">
                                    <div class="mat-header-row text-center">
                                        {{data?.label}}
                                    </div>
                                    <div class="mat-list-item-content mt-2">
                                        <ng-container>
                                            <highcharts-chart
                                                    [Highcharts]="Highcharts"
                                                    [options]="data.chartOption"
                                                    style="width: 100%; height: 400px; display: block;">
                                            </highcharts-chart>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="filterObject.jeuxDonnee===typeJeuDonne.MICROPROJET">
                            <div fxFlex="25" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" class="p-2" *ngFor="let data of chartDiagrammesMicroprojet">
                                <div class="mat-card ng-card">
                                    <div class="mat-header-row text-center">
                                        {{data?.label}}
                                    </div>
                                    <div class="mat-list-item-content mt-2">
                                        <ng-container>
                                            <highcharts-chart
                                                    [Highcharts]="Highcharts"
                                                    [options]="data.chartOption"
                                                    style="width: 100%; height: 400px; display: block;">
                                            </highcharts-chart>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

